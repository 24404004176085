import {Col, Layout, Row, Space, Switch, theme, Button, Tooltip} from "antd";
import React from "react";
import {BulbFilled, BulbOutlined, GithubFilled, LinkedinFilled, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import {ColorModeContext} from "../App";
import "./ResponsiveHeader.css"
import {useNavigate} from "react-router-dom";
import pdfResume from '../assets/David_Hekim_Resume.pdf'

const {Header} = Layout;
const {useToken} = theme;

function ResponsiveHeader() {
    const {theme, token} = useToken();
    const colorMode = React.useContext(ColorModeContext);
    const navigate = useNavigate();

    return (
        <Header
            style={{backgroundColor: token.colorBgContainer, position: "sticky", top: 0, zIndex: 1, padding: 0}}
            className={"responsiveHeader"}
        >
            <Row>
                <Col xs={24} sm={12}>
                    <Space style={{width: "100%", justifyContent: "center"}}>
                        <Tooltip placement="bottom" title={"Landing page"}>
                            <Button type={"text"} size={"large"} onClick={() => {
                                navigate("/")
                            }}>
                                David Hekim
                            </Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"View resume as PDF document"}>
                            <Button type={"text"} size={"large"} href={pdfResume} target="_blank">
                                Resume as PDF
                            </Button>
                        </Tooltip>
                    </Space>
                </Col>
                <Col xs={24} sm={12}>
                    <Space style={{width: "100%", justifyContent: "center"}}>
                        <Tooltip placement="bottom" title={"GitHub"}>
                            <Button type={"default"} shape="circle" icon={<GithubFilled/>} size={"large"}
                                    href={"https://github.com/davidhekim"} target="_blank"/>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"LinkedIn"}>
                            <Button type={"default"} shape="circle" icon={<LinkedinFilled/>} size={"large"}
                                    href={"https://www.linkedin.com/in/david-hekim/"} target="_blank"/>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Call me"}>
                            <Button type={"default"} shape="circle" icon={<PhoneOutlined/>} size={"large"}
                                    href={"tel:+4915736427490"}/>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Send me an email"}>
                            <Button type={"default"} shape="circle" icon={<MailOutlined/>} size={"large"}
                                    href={"mailto:david@hekim.dev"}/>
                        </Tooltip>
                        <Tooltip placement="bottom" title={"Toggle Light-/Dark-Mode"}>
                            <Switch
                                checkedChildren={<BulbFilled/>}
                                unCheckedChildren={<BulbOutlined/>}
                                defaultChecked={theme.id !== 1}
                                onChange={colorMode.toggleColorMode}
                            />
                        </Tooltip>
                    </Space>
                </Col>
            </Row>
        </Header>
    );
}

export default ResponsiveHeader;
