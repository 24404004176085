import {Avatar, Col, Divider, Layout, Row, theme, Typography} from "antd";
import React from "react";
import ResponsiveHeader from "../components/ResponsiveHeader";
import hekimJpg from "../assets/Hekim.jpg";
import "./Home.css"

const {Content} = Layout;
const {Text, Link, Title, Paragraph} = Typography;
const {useToken} = theme;

function Home() {
    const {token} = useToken();

    return (
        <Layout style={{minHeight: "100vh"}}>
            <ResponsiveHeader/>
            <Content className="site-layout"
                     style={{
                         margin: '16px 0',
                         padding: 16,
                         maxWidth: '1200px',
                         width: "100%",
                         alignSelf: "center",
                         background: token.colorBgContainer
                     }}>
                <Row>
                    <Col xs={24} lg={6} style={{display: "inline-grid"}}>
                        <Avatar src={<img src={hekimJpg} alt="avatar"/>}
                                style={{maxWidth: "256px", width: "100%", height: "auto", placeSelf: "center"}}/>
                    </Col>
                    <Col xs={24} lg={{span: 17, offset: 1}} style={{display: "inline-grid"}}>
                        <Title style={{color: token.colorPrimaryText, placeSelf: "center"}}>
                            David Hekim
                        </Title>
                        <Paragraph>
                            Dynamic software developer with a strong educational (M.Sc.) and professional (5+ years)
                            background in IT. Proficient in <Text strong>Java</Text> and <Text
                            strong>Python</Text> software development, DevOps practices, and creating innovative
                            solutions to optimize performance and enhance user experiences. Demonstrated track record of
                            success in developing robust and scalable applications, leveraging agile methodologies, and
                            collaborating within cross-functional teams to deliver high-quality projects. Seeking a
                            challenging software development role to contribute technical expertise and drive innovative
                            software solutions.
                        </Paragraph>
                    </Col>
                </Row>

                <Row gutter={[16, 0]}>
                    <Col span={24} className={"responsiveCol"}>
                        <Title level={3} style={{color: token.colorPrimaryText, marginBottom: 0}}>
                            EXPERIENCE
                        </Title>
                    </Col>
                    <Col span={24}>
                        <Divider style={{marginTop: 0, borderBlockStartColor: token.colorPrimaryText}}/>
                    </Col>

                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Text strong>
                            Senior DevOps Engineer
                        </Text>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Oct 2020 – Present
                        </Text>
                    </Col>
                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Link href={"https://tech.allianz.com/en.html"} target="_blank"
                              style={{color: token.colorPrimaryText}}>
                            Allianz Technology SE
                        </Link>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Munich, Germany
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Paragraph>
                            <ul>
                                <li>
                                    Developed a scalable monitoring solution from scratch, leveraging the ELK-stack and
                                    handling over 21 million logs per day.
                                </li>
                                <li>
                                    Achieved over 800% improvement in incident resolution speed by implementing an
                                    alerting engine via Prometheus and Grafana. Observed a decrease in incident
                                    occurrences and an increase in overall system health since the implementation.
                                </li>
                                <li>
                                    Implemented Elasticsearch Prometheus exporter in Python, transforming log data into
                                    actionable metrics for visualization.
                                </li>
                                <li>
                                    Led software development team and earned full product responsibility for the
                                    monitoring solution as Team Lead Developer. Leading has since improved the team’s
                                    task completion speed by an average of 34%.
                                </li>
                            </ul>
                        </Paragraph>
                    </Col>

                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Text strong>
                            Java Software Developer during Dual Study Program
                        </Text>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Sep 2017 – Sep 2020
                        </Text>
                    </Col>
                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Link href={"https://www.allianz.de/unternehmen/"} target="_blank"
                              style={{color: token.colorPrimaryText}}>
                            Allianz Deutschland AG
                        </Link>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Unterföhring, Germany
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Paragraph>
                            <ul>
                                <li>
                                    Developed and deployed an application to securely organize over 40 teams for major
                                    software releases.
                                </li>
                                <li>
                                    Migrated 7 components of the Allianz Business System from Java 8 to Java 11,
                                    facilitating the adoption of modularization and ensuring a seamless transition with
                                    minimal disruption for over 5500 employees.
                                </li>
                                <li>
                                    Resolved an average of 12 software defects monthly, improving the overall stability
                                    and reliability of the software system.
                                </li>
                            </ul>
                        </Paragraph>
                    </Col>
                </Row>

                <Row gutter={[16, 0]}>
                    <Col span={24} className={"responsiveCol"}>
                        <Title level={3} style={{color: token.colorPrimaryText, marginBottom: 0}}>
                            EDUCATION
                        </Title>
                    </Col>
                    <Col span={24}>
                        <Divider style={{marginTop: 0, borderBlockStartColor: token.colorPrimaryText}}/>
                    </Col>

                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Text strong>
                            Master of Science (M.Sc.) Informatics
                        </Text>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Oct 2020 – Oct 2022
                        </Text>
                    </Col>
                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Link href={"https://www.tum.de/en/"} target="_blank" style={{color: token.colorPrimaryText}}>
                            Technical University Munich
                        </Link>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Garching, Germany
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Paragraph>
                            <ul>
                                <li>
                                    Major in Computer Architecture, Computer Networks and Distributed Systems
                                </li>
                                <li>
                                    Minor in Engineering Software-intensive Systems
                                </li>
                                <li>
                                    Thesis: Application Specific Observability of an IoT Platform in the Cloud
                                </li>
                            </ul>
                        </Paragraph>
                    </Col>

                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Text strong>
                            Bachelor of Science (B.Sc.) Computer Science
                        </Text>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Oct 2017 – Sep 2020
                        </Text>
                    </Col>
                    <Col xs={24} sm={16} className={"responsiveColLeft"}>
                        <Link href={"https://www.unibw.de/home-en"} target="_blank"
                              style={{color: token.colorPrimaryText}}>
                            University of the Federal Armed Forces Munich
                        </Link>
                    </Col>
                    <Col xs={24} sm={8} className={"responsiveColRight"}>
                        <Text>
                            Neubiberg, Germany
                        </Text>
                    </Col>
                    <Col span={24}>
                        <Paragraph>
                            <ul>
                                <li>
                                    Mathematics specialization
                                </li>
                                <li>
                                    Thesis: Development of a Java Module for Processing Architecture Data of Neural
                                    Networks
                                </li>
                            </ul>
                        </Paragraph>
                    </Col>
                </Row>

                <Row gutter={[16, 0]}>
                    <Col span={24} className={"responsiveCol"}>
                        <Title level={3} style={{color: token.colorPrimaryText, marginBottom: 0}}>
                            SKILLS
                        </Title>
                    </Col>
                    <Col span={24}>
                        <Divider style={{marginTop: 0, borderBlockStartColor: token.colorPrimaryText}}/>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Key Competencies:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>Algorithms, Cloud Computing, Machine Learning, Monitoring, Virtualization, Security, SRE,
                            Scrum</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Programming:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>Java, Python, Scala, JavaScript, TypeScript</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>CI/CD:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>Ansible, Docker, Jenkins, Kubernetes, Git, GitHub Actions, Terraform</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Cloud:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>Amazon Web Services (AWS), Google Cloud Platform (GCP), Microsoft Azure</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Monitoring:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>Elasticsearch, Logstash, Kibana, Grafana, MQTT, Jaeger, Zipkin, Dynatrace</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Frameworks:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>Spring Boot, Angular, React.js, Node.js</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Libraries:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>PyTorch, NumPy, SciPy, Pandas, TensorFlow, Keras</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Databases:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>IBM DB2, MySQL, MariaDB, Kafka, NoSQL, MongoDB</Text>
                    </Col>

                    <Col xs={24} lg={4} className={"responsiveCol"}>
                        <Text strong>Languages:</Text>
                    </Col>
                    <Col xs={24} lg={20} className={"responsiveCol"}>
                        <Text>German (Native), Aramaic (Native), English (Professional proficiency – TOEFL
                            certified)</Text>
                    </Col>
                </Row>

                <Row gutter={[16, 0]}>
                    <Col span={24} className={"responsiveCol"}>
                        <Title level={3} style={{color: token.colorPrimaryText, marginBottom: 0}}>
                            ACTIVITIES
                        </Title>
                    </Col>
                    <Col span={24}>
                        <Divider style={{marginTop: 0, borderBlockStartColor: token.colorPrimaryText}}/>
                    </Col>

                    <Col span={24}>
                        <Paragraph>
                            <Text strong>Achievements:</Text>
                            <ul>
                                <li>
                                    Oracle Certified Java EE Associate
                                </li>
                                <li>
                                    Honored by the German Informatics Society for outstanding performance in school
                                </li>
                                <li>
                                    Placed 1st student team among 141 teams at the UniBw CTF
                                </li>
                                <li>
                                    Won highest prize money at GFOS coding competition
                                </li>
                            </ul>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Interests:</Text>
                            <ul>
                                <li>
                                    Capture the Flag (CTF) competitions
                                </li>
                                <li>
                                    Hackathons
                                </li>
                                <li>
                                    Home automation
                                </li>
                                <li>
                                    Open Source
                                </li>
                                <li>
                                    Sudoku
                                </li>
                                <li>
                                    Basketball
                                </li>
                                <li>
                                    Gaming
                                </li>
                            </ul>
                        </Paragraph>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default Home;
