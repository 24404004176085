import React from 'react';
import {ConfigProvider, theme} from "antd";
import MainRoutes from "./components/MainRoutes";

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
});

function App() {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ConfigProvider
                theme={{
                    algorithm: (mode === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm),
                    // token: {colorPrimary: (mode === 'dark' ? '01998D' : '1677ff')}
                }}>
                <MainRoutes/>
            </ConfigProvider>
        </ColorModeContext.Provider>
    );
}

export default App;
